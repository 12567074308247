import React, { useState, useMemo, useEffect } from 'react';
import * as PR from "../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../services/api';
import { fetchCountryInfo } from '../../genericFunctions/GetAllCountries';
import * as utils from '../../utils/reuse'
// import GoogleLoginComponent from './GoogleLogin';
// import AppleLoginComponent from './AppleLogin';
import { Link } from "react-router-dom";
import { handleFirstNameChange, handleLastNameChange, trimFormData } from "../../utils/reuse";
import "../login/forms.scss";
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { parsePhoneNumber } from 'react-phone-number-input'
import { useToast } from '../../context/ToastContext';

const Signup = (props) => {
    const { labels,validations, showToast } = useToast();
    const dispatch = useDispatch();
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);
    const [checked, setChecked] = useState(false);
    const [country, setCountry] = useState([]);
    const [availableProviders, setAvailableProviders] = useState([]);
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneError, setPhoneError] = useState("")

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        callingCode: '',
        password: '',
        confirmPassword: '',
        country: null,
        selectedProvider: 'Default'
    }

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required("VLD0007")
                .min(3, "VLD0008")
                .max(20, "VLD0008"),
            lastName: Yup.string().trim()
                .required("VLD0009")
                .min(3, "VLD0010")
                .max(20, "VLD0010"),
            email: Yup.string().trim()
                .required("VLD0005")
                .email("VLD0006"),
            country: Yup.string()
                .trim()
                .nullable()
                .required("VLD0029"),
            selectedProvider: Yup.string().required("VLD0030"),
            password: Yup.string().trim()
                .required("VLD0001")
                .min(8, "VLD0002")
                .max(20, "VLD0002")
                .matches(/\d/, "VLD0002")
                .matches(/[A-Za-z]/, "VLD0002")
                .strict(true),
            confirmPassword: Yup.string().trim()
                .required("VLD0003")
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "VLD0004"
                    )
                }),
        });
    }

    const submitHandler = formData => {
        
            const trimmedFormData = trimFormData(formData);
            const phone = {
                localPhoneNumber: phoneValue,
                callingCode: trimmedFormData?.callingCode ? trimmedFormData?.callingCode : '',
            }
            const additionalInfo = {
                selectedProvider: trimmedFormData.selectedProvider,
                country: trimmedFormData.country
            }
            const requestObj = { ...trimmedFormData }
            requestObj.phone = phone;
            requestObj.additionalInfo = additionalInfo;
            delete requestObj.callingCode;
            delete requestObj.selectedProvider;
            delete requestObj.country;
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    formik.resetForm();
                    setPhoneValue('');
                    showToast("success",labels?.LBL0282)
                    setTimeout(() => {
                        props.renderFormState(true, "login")
                    }, [3000])
                } else {
                    const error = response.error;
                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    showToast("error",errorMsg)
                }
            }
            signup(requestObj, headers, dispatch, getResponse);
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submitHandler
    })

    useEffect(() => {
        let isValidPhone = parsePhoneNumber(formik.values.phone);
        if(!phoneValue) {
            setPhoneError(validations?.VLD0021)
        } else if(isValidPhone?.nationalNumber.length <=6 || isValidPhone?.nationalNumber === undefined ) {
            setPhoneError(validations?.VLD0019)
        } else {setPhoneError("")}
    }, [formik.values.phone, phoneValue, validations])


    const handleCountryChange = (e) => {
        const selectedValue = e.target.value;
        formik.setFieldValue('country', selectedValue);
        formik.setFieldValue('selectedProvider', '');
        if (selectedValue) {
            const selectedCountryData = country.find((countries) => countries.value === selectedValue);
            if (selectedCountryData?.provider) {
                const providers = selectedCountryData.provider.map(provider => provider.replace(/"/g, ''));
                setAvailableProviders(providers || []);
            }
        }
    };

    const handleCountryBlur = () => {
        formik.setFieldTouched('country', true);
    };

    useEffect(() => {
        const fetchCountryData = async() => {
            const params = {
                countries: '',
                info: ''
            }
            await fetchCountryInfo(params, dispatch, setCountry, showToast);
        };

        fetchCountryData();
    }, [dispatch, setCountry, showToast]);



    return (
        <>
            <div className="form-section">
                <div className="text-right form-top-link">
                    <p>{labels?.LBL0087} <Link onClick={() => props.renderFormState(true, 'login')}>{labels?.LBL0068}</Link></p>
                </div>
                <div className="form">
                    <h3>{labels?.LBL0226}</h3>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="input-field">
                            <PR.InputText id="signup-email" name="email" placeholder={labels?.LBL0037} className="w-12"
                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.errors.email && formik.touched.email
                                ? <div className='error-message'>{validations[formik.errors.email]}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field custom-input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.InputText id="signup-firstName" name="firstName" placeholder={labels?.LBL0001} className="w-12" keyfilter={"alphanum"} value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.firstName && formik.touched.firstName
                                        ? <div className='error-message'>{validations[formik.errors.firstName]}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.InputText id="signup-lastName" name="lastName" placeholder={labels?.LBL0002} className="w-12" keyfilter={"alphanum"}
                                        value={formik.values.lastName} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.lastName && formik.touched.lastName &&
                                        <div className='error-message'>{validations[formik.errors.lastName]}</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-field">
                            <div className="flex flex-column" >
                                <CustomPhoneNumber formik={formik} labels={labels} setPhoneValue={setPhoneValue} phoneError={phoneError}/>
                            </div>
                        </div>

                        <div className="input-field">
                            <PR.Dropdown
                                id="country"
                                value={formik.values.country}
                                name="country"
                                options={country}
                                onChange={handleCountryChange}
                                onHide={handleCountryBlur}
                                resetFilterOnHide
                                optionLabel="label"
                                optionValue="value"
                                showClear placeholder={labels?.LBL0023}
                                style={{ 'width': '100%' }}
                                filter
                                filterBy="label"
                            />
                            {formik.errors.country && formik.touched.country ? <div className='error-message'>{validations[formik.errors.country]}</div> : ''}
                        </div>

                        {formik.values.country &&
                            <div className="input-field">
                                {formik.values.country === 'US' ? 
                                <PR.Dropdown
                                    id="selectedProvider"
                                    name="selectedProvider"
                                    value={formik.values.selectedProvider}
                                    options={availableProviders.length ? availableProviders : ['Default']}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Select a Service Provider"
                                    style={{ 'width': '100%' }}
                                    resetFilterOnHide
                                    filter
                                /> :
                                    <PR.InputText id="selectedProvider" name="selectedProvider" placeholder="Service Provider" className="w-12"
                                        value={formik.values.selectedProvider} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                }
                                {formik.errors.selectedProvider && formik.touched.selectedProvider ? <div className='error-message'>{validations[formik.errors.selectedProvider]}</div> : ''}
                            </div>
                        }

                        <div className="input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='signup-password' name='password' placeholder={labels?.LBL0071} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.password} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} />
                                    {formik.errors.password && formik.touched.password
                                        ? <div className='error-message'>{validations[formik.errors.password]}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='signup-confirmPassword' name='confirmPassword' placeholder={labels?.LBL0005} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.confirmPassword} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} />
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword
                                        ? <div className='error-message'>{validations[formik.errors.confirmPassword]}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex checkbox">
                            <PR.Checkbox inputId="account" name="account" checked={checked} onChange={e => setChecked(e.checked)} />
                            <label htmlFor="account">{labels?.LBL0006}</label>
                        </div>
                        <PR.Button label={labels?.LBL0226} type='submit' disabled={!formik.isValid || !checked || phoneError} className="login-btn signup-btn mt-3" />
                        {/* <span className="or-span">{t("login.or")}</span>
                        <div className="flex mobile-flex gap-3">
                            <GoogleLoginComponent displayName={t("login.googleSignUp")} renderFormState={props.renderFormState} type={"signup"} />
                            <AppleLoginComponent displayName={t("login.appleSignUp")} renderFormState={props.renderFormState} />
                        </div> */}
                    </form>
                </div>
                <div className="form-footer">
                <p>{labels?.LBL0078} <Link to="/privacy-policy" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0075}</Link> {labels?.LBL0077} <Link to="/terms-and-conditions" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0076}.</Link></p>
                </div>
            </div>
        </>
    )
}

export default Signup;